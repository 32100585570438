<template>
  <a-row class="ministry">
    <a-row class="content-title" type="flex">
      <h2 class="flex-1">
        {{ $route.meta.title[$i18n.locale] }}
      </h2>
    </a-row>

    <a-table
      :key="$route.fullPath"
      :columns="columns"
      :data-source="tableProps"
    >
      <template slot="name" slot-scope="item">
        <div
          style="cursor: pointer"
          @click="
            $router.push({
              name: item.name,
              params: {
                type: item.type
              }
            })
          "
        >
          {{ item.title[$i18n.locale] }}
        </div>
      </template>
    </a-table>
  </a-row>
</template>

<script>
// import searchInput from "@/components/wanted/search-input"

const columns = [
  {
    title: "Название",
    key: "title",
    class: "wanted-fio-td",
    scopedSlots: {
      customRender: "name"
    }
  }
]

export default {
  data: () => ({
    tableProps: [
      {
        title: {
          uz: "Virtual qabul",
          oz: "Виртуал қабул",
          ru: "Виртуальная приемная",
          en: "Virtual reception"
        },
        name: "virtual-reception"
      },
      {
        title: {
          oz: "Holis fuqaro",
          uz: "Ҳолис фуқаро",
          qr: "Qalıs puhara",
          ru: "Ҳолис фуқаро",
          en: "Honest citizen"
        },
        name: "corruption"
      },
      {
        title: {
          uz: "Imloviy xato",
          oz: "Имловий хато",
          ru: "Орфографическая ошибка",
          en: "Spelling mistake"
        },
        name: "spelling-mistake"
      }
    ],
    columns,
    langTab: $langPrefix
  }),
  computed: {
    getKey() {
      return this.langTab
    }
  },
  methods: {}
}
</script>
<style>
.wanted-search-input-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 430px;
  flex: 0 0 430px;
  justify-content: space-around;
}
/*.ministry .ant-table-pagination.ant-pagination {
  display: none;
}*/
</style>
